import MESSAGES from 'constants/messages';
import {
  STORAGE_ENDPOINT,
} from 'constants/parameters';


export const fileRequest = ({body, header, endpoint, token, method='POST'}) => {
  const formData = new FormData();
  if (body) Object.keys(body).forEach(key => formData.append(key, body[key]));
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  if (token) myHeaders.append("Authorization", "Bearer " + token);
  if (header) Object.keys(header).forEach(key => myHeaders.append(key, header[key]));
  let requestOptions = {
    method: method,
    headers: myHeaders,
    body: formData
  };

  return fetch(endpoint, requestOptions).then(res => {
    return res?.json();
  })
  .catch(error => ({error}));

}


export const fileUpload = async ({file, fileId, uploadSettings, bucketId, token}) => {
  let error;
  const path = file.path;
  const split = path.split('.');
  const extension = split[split.length - 1].toLowerCase();

  if (uploadSettings?.allowed?.mimetypes && !uploadSettings.allowed.mimetypes.find(mt => file.type.includes(mt))){
    error = MESSAGES['invalid-mimetype']({mimetype: file.type});
  }
  if (uploadSettings?.allowed?.extensions && !uploadSettings.allowed.extensions.includes(extension)) {
    error = MESSAGES['invalid-extension']({extenions: uploadSettings.allowed.extensions.toString()});
  }
  let header = {};
  if (bucketId) {
    header['x-nhost-bucket-id'] = bucketId
  }
  if (fileId) {
    header['x-nhost-file-id'] = fileId
  }
  if (error){
    return {error}
  } else {
    return await fileRequest({
      endpoint: STORAGE_ENDPOINT + (fileId ? '/' + fileId : ''),
      method: fileId ? 'PUT' : 'POST',
      token,
      header,
      body: {
        file,
        bucket_id: bucketId,
        id: fileId
      }
    });
  }
}


export const fileDelete = async ({fileId, token}) => {
  if (fileId && token)
    return await fileRequest({
      endpoint: STORAGE_ENDPOINT + '/' + fileId,
      method: 'DELETE',
      token
    });
}

