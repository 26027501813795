import React, { createContext, useState, useEffect } from 'react';
import Loading from 'components/Loading';
import { useUserData } from '@nhost/react';
import { gql, useLazyQuery } from '@apollo/client';
import { fields } from 'db/users';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const {
    id,
    email,
    displayName,
    locale,
    defaultRole,
    phoneNumber,
    roles,
    metadata,
    createdAt
  } = useUserData();
  const [authUser, setAuthUser] = useState();
  const [loading, setLoading] = useState(true);
  // const [appVersion, setAppVersion] = useState(process.env.REACT_APP_VERSION);
  // const [onlineStatus, setOnlineStatus] = useState();

  const setFilters = (filters) => {
    setAuthUser({
      ...authUser,
      filters: [...filters].sort((a, b) => a.title?.localeCompare(b.title))
    });
  }

  const [getUser] = useLazyQuery(gql`
  query getUsers (
    $id: uuid!
  ){
    user_data(limit: 1, where: {user_id: {_eq: $id}}){
      ${Object.keys(fields).map(fId => '    ' + fId).join('\n')}
      # filters {
      #   id
      # }
    }
  }
  `, {
    onError(err) {
      console.log(err);
    },
    onCompleted(result) {
      console.log(result)
      if (result.user_data) {
        console.log(result.user_data[0])
        setAuthUser({
          ...result.user_data[0],
          // filters: [...result.user_data.filters].sort((a, b) => a.title?.localeCompare(b.title)),
          email,
          displayName,
          locale,
          defaultRole,
          phoneNumber,
          roles,
          metadata,
          createdAt
        });
        setLoading(false);
      }
    }
  });



  // const [getAppVersion] = useLazyQuery(gql`
  // query getVersion (
  //   $client: uuid!,
  //   $user: uuid!,
  //   $location: String,
  //   $agent: String,
  //   $version: String
  // ) {
  //   getVersion(
  //     client: $client,
  //     user: $user,
  //     location: $location,
  //     agent: $agent,
  //     version: $version,
  //     online: true
  //   ) {
  //     version
  //     online
  //   }
  // }`, {
  //   fetchPolicy: 'network-only',
  //   onError: (err) => console.log(err),
  //   onCompleted: (result) => {
  //     if (result && result.getVersion?.version)
  //       setAppVersion(result.getVersion.version);
  //     if (result && result.getVersion?.version) 
  //       setOnlineStatus(result.getVersion.online);
  //   }
  // });


  const checkRole = (role) => {
    let roles = ['admin', ...role];
    if (authUser && authUser.staff){
      let hasRole = false;
      roles.forEach(r => {
        if (authUser.staff.permissions && authUser.staff.permissions.includes(r)) hasRole = true;
      });
      if (hasRole) return true;
    }
    return false;
  }

  useEffect(() => {
    if(!authUser && id){
      console.log(id)
      getUser({variables: {id}});
    }
  },[authUser, getUser, id])

  // useEffect(() => {
  //   const checkVersion = () => {
  //     // getAppVersion({variables: {
  //     //   location: window.location.href,
  //     //   client: process.env.REACT_APP_CLIENT,
  //     //   user: authUser.id,
  //     //   version: process.env.REACT_APP_VERSION
  //     // }});
  //   };
  //   if (authUser) checkVersion();
  // },[authUser, getAppVersion]);

  return (
    <UserContext.Provider
      value={{
        authUser,
        setAuthUser,
        checkRole,
        setFilters
      }}>
      {loading ? <Loading text="Anmeldung am Server läuft..."/> : children}
    </UserContext.Provider>
  );
};
