import React from 'react';
import BlockIcon from '@mui/icons-material/Block';
import WarningIcon from '@mui/icons-material/Warning';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import './Error.css';

const Error = ({error, msg, icon}) => {
  let text = msg;
  let Icon = icon;
  if (!msg && !icon){
    switch(error){
      case '403':
        text = 'Keine Zugriffsrechte für diese Seite.';
        Icon = BlockIcon;
        break;
      case 'file_not_found':
        text = 'Die Datei wurde nicht gefunden.';
        Icon = DoNotDisturbAltIcon;
        break;
      default: //404
        text = 'Seite nicht gefunden.';
        Icon = WarningIcon;
    }
  }
  return <div className='error-wrapper'>
      <div><Icon className='icon'/></div>
      <div className='text'>{text}</div>
    </div>
};

export default Error;
