import { useChangeEmail } from '@nhost/react';
import Form from 'components/Form';
import routeData from 'constants/routeData';
import './Email.css';
import {PATHS} from 'constants/parameters';
import MESSAGES from 'constants/messages';
import { useContext } from 'react';
import Message from 'components/Message';
import { DialogContext } from 'contexts/DialogContext';
import Title from 'components/Title';
import Links from 'components/Buttons/Links';

const emailData = routeData[PATHS.email];

const Email = () => {
  const { changeEmail, isLoading, needsEmailVerification } = useChangeEmail();
  const { showNotification } = useContext(DialogContext);

  const handleOnSubmit = async data => {
    if (data.newEmail && data.newEmail === data.newEmailConfirm){
     const changeEmailData = await changeEmail(data.email);
      if (changeEmailData.isError) {
        showNotification(MESSAGES[changeEmailData.error?.error] || {message: changeEmailData.error?.message});
      }
    } else {
      showNotification(MESSAGES['emails-not-matching'])
    }
  };

  return <div className='profile-wrapper'>
            <Title title={emailData.title} icon={emailData.icon} />
            {needsEmailVerification && <Message message={MESSAGES['verify-email-sent']} />}
            <Form id={PATHS.email} title={emailData.title} disabled={isLoading} icon={emailData.icon} fields={emailData.fields} submitTitle={emailData.submitTitle} loading={isLoading} onSubmit={handleOnSubmit} />
            <Links links={emailData.links.map(link => routeData[link])} />
        </div>
};

export default Email;