import { Navigate } from 'react-router-dom';
import { useResetPassword, useAuthenticationStatus } from '@nhost/react';
import Form from 'components/Form';
import routeData from 'constants/routeData';
import './Reset.css';
import {PATHS} from 'constants/parameters';
import MESSAGES from 'constants/messages';
import Links from 'components/Buttons/Links';
import Message from 'components/Message';
import { useState } from 'react';
import Title from 'components/Title';

const resetData = routeData[PATHS.reset];

const Reset = () => {
  const { resetPassword, isLoading, isSent, isError, error } = useResetPassword();
  const { isAuthenticated } = useAuthenticationStatus();
  const [message, setMessage] = useState();

  const handleOnSubmit = (data) => {
    resetPassword(data.email, { redirectTo: '/'+PATHS.pwd });
    setMessage(MESSAGES['reset-email-sent']);
  };

  if (isAuthenticated) {
    return <Navigate to="/" replace={true} />;
  }

  return <div className='reset-wrapper'>
          <Title title={resetData.title} icon={resetData.icon} />
          {message && isSent ? <Message message={message} /> :
            <Form id={PATHS.reset} title={resetData.title} icon={resetData.icon} fields={resetData.fields} submitTitle={resetData.submitTitle} message={isError && (MESSAGES[error?.error] || {message: error?.message})} loading={isLoading} onSubmit={handleOnSubmit} />}
          <Links links={resetData.links.map(link => routeData[link])} />
        </div>
};

export default Reset;