import { createElement } from "react";
import { Link } from 'react-router-dom';
import { Button } from "@mui/material";
import './Buttons.css';

const Links = ({links}) => {
  return <div style={{display: 'flex', flexDirection: 'column'}}>
  {links.map((link, idx) => {
    return <Button key={'switch-to-'+link.path} to={'/'+link.path} component={Link} className='small-btn'>{createElement(link.icon)} {link.buttonTitle}</Button>
  })}
</div>

}

export default Links;
