import React, {createContext, useRef, useState} from 'react';
import { Dialog, DialogContent, DialogActions, Button, DialogTitle, Snackbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useMutation, gql } from '@apollo/client';

import Message from 'components/Message';
import { useAuthenticationStatus } from '@nhost/react';

export const DialogContext = createContext();

const CREATE_SUPPORT = gql`mutation createSupport (
  $subject: String,
  $text: String,
  $location: String,
  $user_log: jsonb,
  $category: String,
  $user_info: String
) {
  insert_support_one(object: {
    subject: $subject,
    text: $text,
    location: $location,
    user_log: $user_log,
    user_info: $user_info,
    category: $category
  }) {
    id
  }
}`;
const delay = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const DialogProvider = ({ children }) => {
  const msgRef = useRef();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [messageData, setMessageData] = useState();
  const { isAuthenticated, isLoading } = useAuthenticationStatus();
  console.log(isAuthenticated, isLoading)
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [createSupport] = useMutation(CREATE_SUPPORT, {
    onCompleted(result) {
      if (result){
        setDialogData({
          title: 'Danke für dein Feedback!',
          content: <div>Dein Feedback wurde erfolgreich gespeichert!</div>,
          actions: <DialogActions>
            <Button style={{margin: '0 auto'}} onClick={() => onClose()} color="primary">Schließen</Button>
          </DialogActions>
        });
        delay(1000).then(() => {
          setDialogData();
          setDialogOpen(false);
        });
      }
      setLoading(false);
    }
  });

  const reloadApp = () => {
    window.location.reload();
  }
  console.log(messageData)
  const showNotification = ({message, type='error', duration=5000, onHide}) => {
    setMessageData({message, type, duration});
    delay(duration).then(() => {
      setMessageData();
      if (onHide) onHide();
    });
  };

  const closeNotification = () => {

  };
  
  const onClose = () => {
    setDialogOpen(false);
  }

  const onError = err => {
    showNotification({error: err, message: {text: (err ? err.toString() : 'Fehler aufgetreten!')}, type: 'error'});
    setLoading(false);
    window.scrollTo(0, 0);
  }

  // const onSubmitFeedback = (data) => {
  //   const {subject, text, category} = data;
  //   setLoading(true);
  //   let location = window.location.href;
  //   let user_log = JSON.parse(localStorage.getItem('log'));
  //   // let user_info = navigator.userAgent;
  //   localStorage.removeItem('log');
  //   createSupport({variables: {
  //     subject,
  //     text,
  //     location,
  //     user_log,
  //     category
  //   }})
  // }

  // const feedbackDialog = () => {
    // setDialogData({
    //   content: <Form
    //       routeData={routes[ROUTE_SUPPORT]}
    //       id={'support-form'}
    //       create={true}
    //       title={'Feedback'}
    //       icon={SupportIcon}
    //       createValue={{category: 'comment'}}
    //       onSubmit={onSubmitFeedback}
    //       actionData={[]}
    //       loading={loading} />,
    //   actions: <DialogActions>
    //     <Button style={{margin: '0 auto'}} onClick={() => onClose()} color="primary">Schließen</Button>
    //   </DialogActions>
    // });
    // setDialogOpen(true);
  // }

  // useEffect(() => {
  //   const now = new Date();
  //   const greetingTxt = ['Hallo', 'Guten Morgen', 'Guten Abend'];
  //   showNotification({message: {text: ((now.getHours() > 3 && now.getHours() < 11) ? greetingTxt[1] : (now.getHours() >= 18 && now.getHours() < 23) ? greetingTxt[2] : greetingTxt[0]) + (authUser && ', ' + authUser.first_name)}, icon: HandIcon, type: 'info'});
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[]);

  return (
    <DialogContext.Provider
        value={{
          dialogOpen,
          setDialogOpen,
          setDialogData,
          showNotification,
          onError
        }}>
        {children}
        <Dialog
          open={dialogOpen}
          keepMounted
          onClose={() => onClose()}
          fullWidth={true}
          fullScreen={fullScreen}
          maxWidth={'xs'}
        >
          {dialogData?.title && <DialogTitle style={{margin: 0, padding: '16px 8px'}}><h2 style={{margin: 0}}>{dialogData.title}</h2></DialogTitle>}
          {dialogData?.content && <DialogContent style={{margin: 0, padding: '8px'}}>{dialogData.content}</DialogContent>}
          {dialogData?.actions && dialogData.actions}
        </Dialog>
        {messageData && <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={true}
          onClose={() => closeNotification()}
          ><div ref={msgRef}><Message {...messageData} /></div>
        </Snackbar>}
      </DialogContext.Provider>
  );
};

{/* <Fab size="medium" onClick={() => feedbackDialog()} style={{position: 'fixed', bottom: '10px', right: '10px'}} color="secondary" aria-label="feedback">
<CommentIcon />
</Fab> */}
// <div style={{marginBottom: '16px'}}></div>