import { loginFields, loginPwdLessFields, registerFields, resetFields, pwdFields, emailFields, accountFields } from 'db/account/fields';
import ResetIcon from '@mui/icons-material/RotateLeft';
import RegisterIcon from '@mui/icons-material/VpnKey';
import LockIcon from '@mui/icons-material/LockOpen';
import PwdLessIcon from '@mui/icons-material/VpnLock';
import EmailIcon from '@mui/icons-material/Email';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { PATHS } from './parameters';

const routeData = {
  [PATHS.login]: {
    path: PATHS.login,
    icon: LockIcon,
    title: 'Anmeldung',
    submitTitle: 'Anmelden',
    buttonTitle: 'Bereits registriert?',
    fields: loginFields,
    links: [PATHS.pwdless, PATHS.reset]
  },
  [PATHS.register]: {
    path: PATHS.register,
    icon: RegisterIcon,
    title: 'Registrierung',
    submitTitle: 'Registrieren',
    buttonTitle: 'Noch keinen Account?',
    fields: registerFields,
    links: [PATHS.login]
  },
  [PATHS.pwdless]: {
    path: PATHS.pwdless,
    icon: PwdLessIcon,
    title: 'Anmeldung',
    submitTitle: 'Anmeldecode senden',
    buttonTitle: 'Ohne Passwort anmelden?',
    fields: loginPwdLessFields,
    links: [PATHS.register, PATHS.login]
  },
  reset: {
    id: 'reset',
    path: PATHS.reset,
    icon: ResetIcon,
    title: 'Passwort zurücksetzen',
    submitTitle: 'Zurücksetzen',
    buttonTitle: 'Passwort vergessen?',
    fields: resetFields,
    links: [PATHS.login],//[PATHS.register, PATHS.login, PATHS.pwdless],
  },
  [PATHS.pwd]: {
    path: PATHS.pwd,
    icon: LockIcon,
    title: 'Passwort ändern',
    submitTitle: 'Passwort übernehmen',
    buttonTitle: 'Passwort ändern?',
    links: [PATHS.account],
    fields: pwdFields
  },
  [PATHS.email]: {
    path: PATHS.email,
    icon: EmailIcon,
    title: 'E-Mail ändern',
    submitTitle: 'E-Mail ändern',
    buttonTitle: 'E-Mail ändern?',
    links: [PATHS.account],
    fields: emailFields
  },
  [PATHS.account]: {
    path: PATHS.account,
    icon: ManageAccountsIcon,
    title: 'Account',
    submitTitle: 'Änderungen übernehmen',
    buttonTitle: 'Account Daten ändern?',
    fields: accountFields,
    links: [PATHS.email, PATHS.pwd]
  }
}


export default routeData;
