import { useChangePassword } from '@nhost/react';
import Form from 'components/Form';
import routeData from 'constants/routeData';
import './Pwd.css';
import {PATHS} from 'constants/parameters';
import MESSAGES from 'constants/messages';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { DialogContext } from 'contexts/DialogContext';
import Title from 'components/Title';
import Links from 'components/Buttons/Links';

const pwdData = routeData[PATHS.pwd];

const Pwd = () => {
  const { showNotification } = useContext(DialogContext);
  const { changePassword, isLoading, isSuccess } = useChangePassword();

  const handleOnSubmit = async data => {
    if (data.newPassword && data.newPassword === data.newPasswordConfirm){
      const changePwdData = await changePassword(data.newPassword);
      if (changePwdData.isError) {
        showNotification(MESSAGES[changePwdData.error?.error] || {message: changePwdData.error?.message})
      } else if (changePwdData.isSuccess) {
        showNotification(MESSAGES['password-changed'])
      }
    } else {
      showNotification(MESSAGES['passwords-not-matching'])
    }
  };

  if (isSuccess) {
    return <Navigate to="/" replace={true} />;
  }

  return <div className='profile-wrapper'>
            <Title title={pwdData.title} icon={pwdData.icon} />
            <Form id={PATHS.pwd} title={pwdData.title} disabled={isLoading || isSuccess} icon={pwdData.icon} fields={pwdData.fields} submitTitle={pwdData.submitTitle} loading={isLoading} onSubmit={handleOnSubmit} />
            <Links links={pwdData.links.map(link => routeData[link])} />
        </div>
};

export default Pwd;