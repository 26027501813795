import { Navigate } from 'react-router-dom';
import { useSignInEmailPasswordless, useAuthenticationStatus } from '@nhost/react';
import Form from 'components/Form';
import routeData from 'constants/routeData';
import './Pwdless.css';
import {PATHS} from 'constants/parameters';
import MESSAGES from 'constants/messages';
import Links from 'components/Buttons/Links';
import { useContext } from 'react';
import { DialogContext } from 'contexts/DialogContext';
import Title from 'components/Title';

const pwdlessData = routeData[PATHS.pwdless];

const Pwdless = () => {
  const { signInEmailPasswordless, isLoading, isSuccess} = useSignInEmailPasswordless();
  const { isAuthenticated } = useAuthenticationStatus();
  const { showNotification } = useContext(DialogContext);


  const handleOnSubmit = async (data) => {
    const signInData = await signInEmailPasswordless(data.email);
    if (signInData.isError){
      showNotification(MESSAGES[signInData.error?.error] || {message: signInData.error?.message})
    } else if (signInData.isSuccess) {
      showNotification(MESSAGES['login-email-sent']);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" replace={true} />;
  }

  return <div className='pwdless-wrapper'>
          <Title title={pwdlessData.title} icon={pwdlessData.icon} />
          <Form id={PATHS.login} disabled={isSuccess} title={pwdlessData.title} icon={pwdlessData.icon} fields={pwdlessData.fields} submitTitle={pwdlessData.submitTitle} loading={isLoading} onSubmit={handleOnSubmit} />
          <Links links={pwdlessData.links.map(link => routeData[link])} />
        </div>
};

export default Pwdless;