import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1400,
    },
  },
  typography: {
    fontFamily: 'Space Grotesk',
    fontWeightRegular: 400,
    // fontWeightLight: 300,
    // fontWeightMedium: 500
  },
  palette: {
    primary: {
      main: '#252525',
    },
    secondary: {
      main: '#19857b',
    },
    typography: {
      useNextVariants: true
    },
    error: {
      main: red.A400,
    },
    background: {
      default: 'rgba(255, 255, 255, 1)'
    },
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  flexRowRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right'
  },
  flexRowLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left'
  },
  container: {
    width: '100%',
    maxWidth: '900px',
    margin: '0 auto'
  },
  components: {
    MUIRichTextEditor: {
      styleOverrides: { 
        root: {
          width: '100%',
          minHeight: '300px'
        },
        toolbar: {
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          '& button': {
            borderRadius: 0
          }
        }
      }
    }
  }
});

export default theme;

export const shadow = '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1)';
