import ImportExportIcon from '@mui/icons-material/ImportExport';
import SubjectIcon from '@mui/icons-material/Subject';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import CodeIcon from '@mui/icons-material/WbIridescent';
import ContactIcon from '@mui/icons-material/PermContactCalendar';
import PhoneIcon from '@mui/icons-material/Phone';

export const categories = [
  { id: 'export', title: 'Drittlandstransfer', icon: ImportExportIcon},
  { id: 'misc', title: 'Sonstiges', icon: SubjectIcon}
]
export const accountFields = [
  { title: 'Vorname', id: 'firstName', required: true, validation: [{validation: 'required'}, {validation: 'minChars', min: 2}] },
  { title: 'Nachname', id: 'lastName', required: true, validation: [{validation: 'required'}, {validation: 'minChars', min: 2}] },
  { title: 'Telefon', id: 'phone', type: 'phone'}
]

export const newEmailFields = [
  { title: 'Neue E-Mail Adresse', icon: EmailIcon, id: 'email', type: 'email', required: true, validation: [{validation: 'required'}, {validation: 'isEmail'}]}
]
export const newEmailFieldsRead = [
  { title: 'E-Mail Adresse', icon: EmailIcon, id: 'current_email', type: 'email'}
]

export const loginFields = [
  { title: 'E-Mail', icon: EmailIcon, id: 'email', type: 'email', props: {autoComplete: 'username'}, required: true },
  { title: 'Passwort', icon: LockIcon, type: 'password', id: 'password', props: {autoComplete: 'current-password'}, required: true}
]

export const loginPwdLessFields = [
  { title: 'E-Mail', icon: EmailIcon, id: 'email', type: 'email', props: {autoComplete: 'username'}, required: true }
]

export const pwdFields = [
  { title: 'Neues Passwort', icon: LockIcon, type: 'password', id: 'newPassword', required: true},
  { title: 'Passwort Bestätigung', icon: LockIcon, type: 'password', id: 'newPasswordConfirm', required: true}
]

export const emailFields = [
  { title: 'Neue E-Mail Adresse', icon: LockIcon, type: 'email', id: 'newEmail', required: true},
  { title: 'E-Mail Adresse Bestätigung', icon: LockIcon, type: 'email', id: 'newEmailConfirm', required: true}
]


// id: string;
// title: string;
// required?: undefined | boolean;
// max?: undefined | number;
// min?: undefined | number;
// type?: undefined | string;
// consent?: undefined | IConsent;

export const registerFields = [
  { title: 'E-Mail', id: 'email', type: 'email', icon: EmailIcon, required: true, validation: [{validation: 'required'}, {validation: 'isEmail'}]},
  { title: 'Password', id: 'password', type: 'password', icon: LockIcon, required: true, validation: [{validation: 'required', text: 'Passwort fehlt.'}]},
  { title: 'Vorname', id: 'first_name', icon: ContactIcon, required: true, validation: [{validation: 'required'}, {validation: 'minChars', min: 2}] },
  { title: 'Nachname', id: 'last_name', icon: ContactIcon, required: true, validation: [{validation: 'required'}, {validation: 'minChars', min: 2}] },
  { title: 'Telefon', id: 'phone', icon: PhoneIcon, type: 'phone'}
]

export const resetFields = [
  { title: 'E-Mail', icon: EmailIcon, id: 'email', type: 'email', validation: [{validation: 'required'}, {validation: 'isEmail'}]},
];

export const resetFieldsCode = [
  { title: 'Neues Passwort', icon: LockIcon, type: 'password', id: 'new_password', required: true, validation: [{validation: 'required'}]} ,
  { title: 'E-Mail-Code', icon: EmailIcon, id: 'code', required: true, validation: [{validation: 'required'}]}
];

export const pwdfields = [
  { title: 'Altes Passwort', icon: LockIcon, type: 'password', field: 'old_password', required: true, validation: [{validation: 'required'}]},
  { title: 'Neues Passwort', icon: LockIcon, type: 'password', field: 'new_password', required: true, validation: [{validation: 'required'}]}
]

export const pwdfieldsRead = [
  { title: 'Passwort', icon: LockIcon, type: 'password', field: 'current_password', required: true, validation: [{validation: 'required'}]}
]
