import React from 'react';
import Loading from 'components/Loading';
import { useAuthenticationStatus } from '@nhost/react';
import { Navigate, Outlet } from 'react-router-dom';
import { PATHS } from 'constants/parameters';
import Header from 'components/Header';
import './Auth.css';
import { UserProvider } from 'contexts/UserContext';

const Auth = () => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus();
  
  return (
    <div className="auth-wrapper">
      {isLoading ? <Loading text="zimt.app wird geladen..."/> : !isAuthenticated ? <Navigate to={'/'+PATHS.login} /> : <UserProvider>
        <Header />
        <Outlet />
      </UserProvider>}
    </div>
  );
};

export default Auth;