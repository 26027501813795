import React from 'react'

import LoadingCircle from 'static/img/loading.svg';
import LogoIcon from 'static/img/logo-zimtapp.svg';
import './Loading.css';

const Loading = ({text}) => {

  return (
    <div className="wrapper-loading">
      <div className="loading">
        <img src={LoadingCircle} style={{width: '200px', height: '200px', margin: '-100px 0 0 -100px'}} alt="" />
        <img src={LogoIcon} style={{width: '100px', height: '100px', margin: '-50px 0 0 -50px'}} alt="" />
      </div>
      <span>{text}</span>
    </div>
  );
};

export default Loading;
