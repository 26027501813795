import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import theme from 'theme';
import { CssBaseline } from '@mui/material';
import { useForm, FormProvider } from "react-hook-form";
import '@fontsource/space-grotesk';
import { NhostApolloProvider } from '@nhost/react-apollo';
import { NhostReactProvider } from '@nhost/react';
import { server } from 'api/connect';
import Auth from 'components/Routes/Auth';
import User from 'components/User';
import Login from 'components/Login';
import { PATHS } from 'constants/parameters';
import Reset from 'components/Reset';
import Pwdless from 'components/Pwdless';
import Pwd from 'components/Pwd';
import Email from 'components/Email';
import Account from 'components/Account';
import { DialogProvider } from 'contexts/DialogContext';
import Error from 'components/Error';

export default function App() {

	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <FormProvider methods={useForm()}>
                    <NhostReactProvider nhost={server}>
                        <NhostApolloProvider nhost={server}>
                            <DialogProvider>
                                <BrowserRouter>
                                    <Routes>
                                        <Route path="/" element={<Auth />}>
                                            <Route index element={<User />} />
                                            <Route path={"/"+PATHS.account} element={<Account />} />
                                            <Route path={"/"+PATHS.pwd} element={<Pwd />} />
                                            <Route path={"/"+PATHS.email} element={<Email />} />
                                        </Route>
                                        <Route path={"/"+PATHS.login} element={<Login />} />
                                        <Route path={"/"+PATHS.pwdless} element={<Pwdless />} />
                                        <Route path={"/"+PATHS.reset} element={<Reset />} />
                                        <Route path="*" element={<Error error={404} />} />
                                    </Routes>
                                </BrowserRouter>
                            </DialogProvider>
                        </NhostApolloProvider>
                    </NhostReactProvider>
                </FormProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
// {Object.keys(routeData).map(route => <Route key={route} path={"/"+route} element={<Form path={route} />} />)}
