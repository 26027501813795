import { Navigate } from 'react-router-dom';
import { useSignInEmailPassword, useAuthenticationStatus } from '@nhost/react';
import Form from 'components/Form';
import routeData from 'constants/routeData';
import './Login.css';
import {PATHS} from 'constants/parameters';
import MESSAGES from 'constants/messages';
import Links from 'components/Buttons/Links';
import VerifyEmail from '../Buttons/VerifyEmailButton';
import { useContext, useState } from 'react';
import { DialogContext } from 'contexts/DialogContext';
import Title from 'components/Title';

const loginData = routeData[PATHS.login];

const Login = () => {
  const { showNotification } = useContext(DialogContext);
  const {
    signInEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
  } = useSignInEmailPassword();
  const [email, setEmail] = useState();
  const { isAuthenticated } = useAuthenticationStatus();

  const handleOnSubmit = async (data) => {
    setEmail(data.email);
    const signInData = await signInEmailPassword(data.email, data.password);
    if (signInData.isError){
      showNotification((MESSAGES[signInData.error?.error] || {message: signInData.error?.message}));
    }
  };

  if (isSuccess || isAuthenticated) {
    return <Navigate to="/" replace={true} />;
  }

  return <div className='login-wrapper'>
          <Title title={loginData.title} icon={loginData.icon} />
          {needsEmailVerification ? <VerifyEmail email={email} /> : <>
              <Form id={PATHS.login} title={loginData.title} icon={loginData.icon} fields={loginData.fields} submitTitle={loginData.submitTitle} loading={isLoading} onSubmit={handleOnSubmit} />
              <Links links={loginData.links.map(link => routeData[link])} />
            </>}
        </div>
};

export default Login;