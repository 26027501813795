import { useSendVerificationEmail } from '@nhost/react';
import MESSAGES from 'constants/messages';
import { Button } from '@mui/material';
import './Buttons.css';
import Message from 'components/Message';

const VerifyEmail = ({email}) => {
  const { sendEmail, isLoading, isSent, isError, error } = useSendVerificationEmail();

  return <>
      <Message message={isError ? {message: error.message} : isSent ? MESSAGES['verify-email-sent'] : MESSAGES['needs-email-verification']} />
      <Button className='small-btn' disabled={!email || isLoading || isSent} onClick={() => sendEmail(email)}>Verifizierung erneut senden?</Button>
    </>
};

export default VerifyEmail;