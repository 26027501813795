import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

const NavItem = ({id, to, onClick, icon, title}) => {
  const Icon = icon;
  return <MenuItem key={'top-nav-'+id} component={to && Link} onClick={onClick} to={to}>
  <ListItemIcon>
    <Icon fontSize="small" />
  </ListItemIcon>
  <ListItemText>{title}</ListItemText>
</MenuItem>
}

export default NavItem;