export const ENDPOINT = '/endpoint/v1/graphql';
export const ENDPOINT_HTTP_PROTOCOL = process.env.NODE_ENV === 'development' ? 'http://' : 'https://';
export const ENDPOINT_WS_PROTOCOL = process.env.NODE_ENV === 'development' ? 'ws://' : 'wss://';

export const ENDPOINT_BASE = window.location.origin;
export const STORAGE_ENDPOINT_BASE = ENDPOINT_BASE + '/v1/storage';
export const STORAGE_ENDPOINT = STORAGE_ENDPOINT_BASE + '/files';
export const LOGIN_ENDPOINT = ENDPOINT_BASE + '/signin/email-password/';
export const LOGIN_PWDLESS_ENDPOINT = ENDPOINT_BASE + '/signin/passwordless/email';
export const LOGOUT_ENDPOINT = ENDPOINT_BASE + '/signout';
export const REFRESH_ENDPOINT = '/token';
export const REGISTER_ENDPOINT = ENDPOINT_BASE + '/signup/email-password';
export const CHANGE_PWD_ENDPOINT = ENDPOINT_BASE + '/user/password';
export const USER_INFO_ENDPOINT = ENDPOINT_BASE + '/user';
export const RESET_PWD_ENDPOINT = ENDPOINT_BASE + '/user/password/reset';
export const NEW_PWD_ENDPOINT = ENDPOINT_BASE + '/user/password';
export const CHANGE_EMAIL_ENDPOINT = ENDPOINT_BASE + '/user/email/change';
export const EMAIL_CODE_ENDPOINT = ENDPOINT_BASE + '/user/email/change';
export const AVATAR_DIR = 'avatars';

export const ENDPOINT_BASE_ACCOUNT = ENDPOINT_BASE + 'account/'
export const ENDPOINT_BASE_ACCOUNT_PWD = ENDPOINT_BASE_ACCOUNT + 'credentials/password/'

export const PATHS = {
  register: 'register',
  login: 'login',
  pwdless: 'login-link',
  reset: 'reset',
  pwd: 'pwd',
  user: 'user',
  email: 'email',
  account: 'account'
}

// USER NAVI
export const HEADER_NAVI = [PATHS.account];
