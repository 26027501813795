import { Alert, AlertTitle } from "@mui/material";

const Message = ({message, type, title}) => {
  console.log(message, type, title)
  return message ? <Alert severity={type || 'error'} style={{marginBottom: '24px'}}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </Alert> : <></>
}

export default Message;
