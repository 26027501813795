import React, { useState, useCallback, useContext } from 'react';

import { CircularProgress, FormHelperText, IconButton } from '@mui/material';

import PlusIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import './Image.css';
import { DialogContext } from 'contexts/DialogContext';
import { STORAGE_ENDPOINT } from 'constants/parameters';
import MESSAGES from 'constants/messages';
import { fileDelete, fileUpload } from 'api/files';
import { useAccessToken } from '@nhost/react';

const ImageUpload = ({
  onComplete,
  onDeleteComplete,
  title,
  bucketId,
  helperText,
  allowDelete,
  overwriteExisting,
  fileId,
  heightToWidth=1,
  allowed={
    extensions: ['jpg', 'pjpeg', 'pjp', 'jpeg', 'png', 'gif', 'apng', 'avif', 'svg', 'webp'],
    mimetypes: ['image']
  },
  style,
  maxWidth
}) => {
  const [uploading, setUploading] = useState(false);
  const token = useAccessToken();
  const { showNotification } = useContext(DialogContext);
  const onDelete = useCallback(async (fileId, onComplete) => {
    setUploading(true);
    const delData = await fileDelete({token, fileId});
    if (delData.error) {
      showNotification(MESSAGES[delData.error?.error] || {message: delData.error?.message});
    } else {
      if (onComplete) onComplete(delData)
    }
    setUploading(false);
  },[showNotification, token]);

  const onUpload = useCallback(async (files) => {
    if (files.length > 1) {
      showNotification(MESSAGES['too-many-files']);
    } else {
      setUploading(true);
      if(fileId && !overwriteExisting){
        await onDelete(fileId);
      }
      const upldData = await fileUpload({file: files[0], token, bucketId: bucketId, fileId: overwriteExisting && fileId, uploadSettings: {allowed}});
      if (upldData.error) {
        showNotification(MESSAGES[upldData.error?.error] || {message: upldData.error?.message});
      } else {
        onComplete(upldData);
      }
      setUploading(false);
    }
  },[showNotification, fileId, overwriteExisting, token, bucketId, allowed, onDelete, onComplete]);



  const onDrop = useCallback(files => {
    onUpload(files);
  }, [onUpload])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return <div className='img-wrapper' style={{maxWidth: maxWidth ? maxWidth : '100%', width: '100%', ...style}}>
    {allowDelete && fileId && <IconButton onClick={() => onDelete(fileId, onDeleteComplete)} className='delete-img'><DeleteIcon /></IconButton>}
    <div className='drope-zone-image' style={{paddingBottom: heightToWidth * 100 + '%', maxWidth: maxWidth ? maxWidth + 'px' : '100%', ...style}} {...getRootProps()}>
        {uploading ? <CircularProgress classes={{root: 'progress'}} style={{width: '100%', height: '100%'}} thickness={2} /> : <><PlusIcon className='img-back' /><input {...getInputProps()} /></>}
        <div className='image-wrapper' style={{opacity: isDragActive || uploading ? .5 : 1, ...style}}>
          {fileId && <img className='img' alt={title} src={STORAGE_ENDPOINT + '/' + fileId + (maxWidth ? '?w=' + maxWidth : '')} style={style} />}
        </div>
      </div>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>;
};

export default ImageUpload;