// MESSAGES
const MESSAGES = {
  'invalid-email': {
    title: 'E-Mail Adresse ungültig',
    message: 'Ungültiges Format der E-Mail Adresse.'
  },
  'needs-email-verification': {
    title: 'E-Mail Adresse noch nicht verifiziert',
    message: 'Bitte überprüfen Sie Ihr Postfach folgen Sie dem Link zur Verifizierung Ihrer E-Mail.'
  },
  'passwords-not-matching': {
    title: 'Passwörter stimmen nicht überein',
    message: 'Bitte geben Sie zweimal das selbe Passwort an.'
  },
  'emails-not-matching': {
    title: 'E-Mails stimmen nicht überein',
    message: 'Bitte geben Sie als Bestätigung die E-Mail zweimal an.'
  },
  'too-many-files': {
    title: 'Zu viele Dateien',
    message: 'Sie können nur eine Datei uploaden.'
  },
  'invalid-extension': ({extenions}) => ({
    title: 'Ungültige Dateiextension',
    message: 'Es sind folgende Dateiendungen erlaubt: ' + extenions
  }),
  'invalid-mimetype': ({mimetype}) => ({
    title: 'Ungültige Mime-Type',
    message: `Der Mime-Type der Datei (${mimetype}) ist nicht erlaubt!`
  }),
  'login-email-sent': {
    title: 'E-Mail verschickt',
    message: 'Ein E-Mail mit dem Login-Link wurde verschickt. Bitte überprüfen Sie ihr Postfach und folgen Sie dem Link.',
    type: 'info'
  },
  'verify-email-sent': {
    title: 'E-Mail verschickt',
    message: 'Der Link zur verifizierung der E-Mail Adresse wurde verschickt. Bitte überprüfen Sie ihr Postfach und folgen Sie dem Link.',
    type: 'info'
  },
  'reset-email-sent': {
    title: 'E-Mail verschickt',
    message: `Ein E-Mail wurde verschickt. Bitte folgen Sie dem Link um das Passwort zurückzusetzen.`,
    type: 'info'
  },
  'changed-success': {
    title: 'Änderung erfolgreich',
    message: `Die Änderunggen wurden erfolgreich übernommen.`,
    type: 'success'
  },
  'password-changed': {
    title: 'Passwort geändert',
    message: 'Das Passwort wurde erfolgreich geändert!',
    type: 'success'
  },
  'image-success': {
    title: 'Bild hochgeladen',
    message: 'Das Bild wurde erfolgreich hochgeladen!',
    type: 'success'
  },
  'session-timeout': {
    title: 'Session abgelaufen',
    message: 'Bitte erneut einloggen.',
    type: 'info'
  }
}

export default MESSAGES;
