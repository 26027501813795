import {
  Avatar,
  Button,
  Menu,
} from '@mui/material';
// import AppsIcon from '@mui/icons-material/Apps';
import HomeImg from 'static/img/logo-zimtapp.svg';
import { Link } from 'react-router-dom';
import { useAvatarUrl, useSignOut } from '@nhost/react';
import { useContext, useState } from 'react';
import { HEADER_NAVI, STORAGE_ENDPOINT } from 'constants/parameters';
import routeData from 'constants/routeData';
import NavItem from './NavItem';
import LogoutIcon from '@mui/icons-material/Logout';
import './Header.css';
import PersonIcon from '@mui/icons-material/Person';
import { UserContext } from 'contexts/UserContext';

const Header = () => {
  const { authUser } = useContext(UserContext);

  const { signOut } = useSignOut();
  const [anchorElUser, setAnchorElUser] = useState();
  return <div className='header-wrapper'>
    <ul className='header'>
      <li style={{minWidth: '52px'}}><Button component={Link} to={'/'} style={{height: '52px', minWidth: '52px', width: '52px', padding: 0}}><img alt="Home" style={{width: '36px', height: '36px', margin: 0}} src={HomeImg} /></Button></li>
      <li style={{minWidth: '52px', flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          elevation={1}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={() => setAnchorElUser()}
        >
          {HEADER_NAVI.map((item) => <NavItem key={'top-nav-'+item} id={item} to={routeData[item].path} onClick={() => setAnchorElUser()} icon={routeData[item].icon} title={routeData[item].title} />)}
          <NavItem id={'logout'} onClick={() => {
            setAnchorElUser();
            signOut();
          }} icon={LogoutIcon} title={'Logout'} />
        </Menu>
        <Button style={{height: '52px', minWidth: '52px'}} onClick={e => setAnchorElUser(e.currentTarget)}><Avatar src={authUser?.avatar_file_id && STORAGE_ENDPOINT + '/' + authUser?.avatar_file_id + '?w=52'}>{!authUser?.avatar_file_id && <PersonIcon style={{color: '#fff'}} />}</Avatar></Button>
      </li>
    </ul>
  </div>
}

export default Header

{/* <Button style={{height: '52px', minWidth: '52px'}} onClick={() => {}}><AppsIcon style={{color: '#fff'}} /></Button> */}
