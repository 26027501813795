import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import style from './Form.style';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { DialogContext } from 'contexts/DialogContext';

const useStyles = makeStyles(style);

const Form = ({id, title, icon, fields, onSubmit, submitTitle, message, consent, loading, disabled, values}) => {
  const classes = useStyles();
  const { showNotification } = useContext(DialogContext);
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [consentAccepted, setConsentAccepted] = useState(consent?.[id] ? false : true);
  const { control, reset, formState: { errors }, handleSubmit } = useForm({
    shouldUnregister: true,
    mode: 'onBlur',
  });

  useEffect(() => {
    reset()
  },[reset, id])

  useEffect(() => {
    if (message) showNotification(message);
  },[message, showNotification])

  return <>
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            {fields.map((f, idx) => {
              let fieldRules = {
                required: f.required ? 'Dieses Feld muss ausgefüllt werden.' : undefined,
                maxLength: f.max ? {
                  value: f.max,
                  message: 'Dieses Feld darf maximal ' + f.max + ' Zeichen lang sein.'} : undefined,
                minLength: f.min ? {
                  value: f.min,
                  message: 'Dieses Feld muss mindestens ' + f.min + ' Zeichen lang sein.'} : undefined,
                pattern: f.type === 'email' ? {
                  value: /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/i,
                  message: 'Ungültige E-Mail Adresse.'
                } : f.type === 'password' ? {
                  value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i,
                  message: 'Das Passwort muss mindestens 8 Zeichen lang sein, wovon mindestens einer ein Buchstabe und einer eine Zahl ist.'
                } : undefined
              };
              let Field;
              let Icon = f.icon;
              let props = {};
              switch (f.type) {
                case 'checkbox':
                  Field = FormControlLabel;
                  props = {labelPlacement:"end", className: classes.checkbox, control: <Checkbox style={{margin: '-8px 0 auto 4px'}} />, label: <div>{f.consent && f.consent.text}</div>}
                  break;
                case 'password':
                  props = {
                    InputProps: {
                      endAdornment: <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={event => {event.preventDefault();}}
                            size="large">
                            {showPassword ? <VisibilityOffIcon className={classes.inputIcon} /> : <VisibilityIcon className={classes.inputIcon} />}
                          </IconButton>
                        </InputAdornment>
                    },
                    type: showPassword ? 'text' : 'password',
                    fullWidth: true,
                    label: f.title,
                    helperText: errors[f.id] && errors[f.id].message
                  }
                  Field = TextField;
                  break;
                case 'textarea': 
                  Field = TextField;
                  props = {fullWidth: true, rows: 6, multiline: true, label: f.title, helperText: errors[f.id] && errors[f.id].message}
                  break;
                default:
                  Field = TextField;
              }
              props = {
                ...props,
                InputProps: f.icon ? {
                  ...props.InputProps,
                  startAdornment: <InputAdornment position="start">
                    <Icon className={classes.inputIcon} />
                  </InputAdornment>
                } : props.InputProps,
                fullWidth: true,
                label: f.title,
                helperText: errors[f.id]?.message || ' '
              }
              return <div className={classes.field} key={'fld-'+idx}>
                {f.type === 'callback' && f.consent && <FormControlLabel
                  labelPlacement="end"
                  disabled={disabled || loading}
                  control={<Checkbox disabled={disabled || loading} style={{margin: '-8px 0 auto 4px'}} checked={checked} onChange={() => setChecked(!checked)} name={'cb_'+f.id} />}
                  label={f.consent.text}
                  className={classes.checkboxCallback}
                />}
                {(f.type !== 'callback' || (f.type === 'callback' && checked)) &&
                <Controller
                  control={control}
                  rules={fieldRules}
                  name={f.id}
                  defaultValue={values?.[f.id] || ''}
                  render={({ field }) => <Field
                      variant="outlined"
                      error={errors[f.id] ? true : false}
                      disabled={disabled || loading}
                      required={f.required}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                      {...f.props}
                      {...props} />
                  } />}
                {f.consent && f.consent.links && <>{f.consent.linksTitle && <h5 className={classes.consentLinkHl}>{f.consent.linksTitle}</h5>}<ul className={classes.consentLinks}>{f.consent.links.map((link,idx) => <li key={'co-lnk-'+idx}><a target="_blank" rel="noopener noreferrer" href={link.url}>{link.title ? link.title : link.url}</a></li>)}</ul></>}
              </div>
            })}
            {consent && <>
              <FormControlLabel
                labelPlacement="end"
                disabled={disabled || loading}
                style={{fontSize: '90%'}}
                control={<Checkbox required={true} style={{margin: '-8px 0 auto 4px'}} checked={consentAccepted} onChange={() => setConsentAccepted(!consentAccepted)} name="consent" />}
                label={consent.text}
                className={classes.checkbox}
              />
              {consent?.links !== undefined && <>{consent?.linksTitle && <h5 className={classes.consentLinkHl}>{consent.linksTitle}</h5>}<ul className={classes.consentLinks}>{consent.links.map((link,idx) => <li key={'co-lnk-'+idx}><a target="_blank" rel="noopener noreferrer" href={link.url}>{link.title ? link.title : link.url}</a></li>)}</ul></>}
            </>}
            <div style={{margin: '16px 0'}}>
              <Button
                disabled={disabled || !consentAccepted || loading}
                fullWidth
                disableElevation
                variant="contained"
                color="secondary"
                type="submit"
                className={classes.btn}>
                {loading ? <CircularProgress style={{width: '24px', height: '24px'}} /> : submitTitle}
              </Button>
            </div>
            {consent && <div style={{fontSize: '12px'}}>
              {(!consentAccepted || (errors && Object.keys(errors).length > 0)) && <p style={{textAlign: 'center', color: 'rgb(226,6,19)'}}><ErrorOutlineIcon style={{height: '10px'}} />
                {!consentAccepted ? (consent.notaccepted ? consent.notaccepted : 'Das Abschicken dieses Formulars erfordert die Einwilligung unser Compliance-Richtlinien.')
                : (errors && Object.keys(errors).length > 0 ? 'Ungültige Eingabe: Bitte überprüfen Sie Ihre Angaben im Formular.' : '')}
              </p>}
            </div>}
          </form>
        </>
}

export default Form;
