import React, { useContext } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

import './User.css';
import Title from 'components/Title';
import { UserContext } from 'contexts/UserContext';

const now = new Date();
const greetingTxt = ['Hallo', 'Guten Morgen', 'Guten Abend'];

const User = () => {
  const { authUser } = useContext(UserContext);


  return <>
    <Title title={((now.getHours() > 3 && now.getHours() < 11) ? greetingTxt[1] : (now.getHours() >= 18 && now.getHours() < 23) ? greetingTxt[2] : greetingTxt[0]) + ' ' + authUser?.displayName} />
    <div className="dashboard-wrapper">
    {(authUser?.roles.includes('staff') || authUser?.roles.includes('manager')) && <Card className="card" onClick={() => window.location.href = 'https://zimt.app/office'}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="https://zimtnow.com/assets/images/slider_zimtnow_1.jpg"
          alt="zimtApp"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            zimtApp
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Where fun meets productivity 
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>}
    </div> 
  </>
};

export default User;
