import Form from 'components/Form';
import routeData from 'constants/routeData';
import {PATHS} from 'constants/parameters';
import MESSAGES from 'constants/messages';
import { useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import ImageUpload from 'components/Files/Image';
import Title from 'components/Title';
import { DialogContext } from 'contexts/DialogContext';
import Links from 'components/Buttons/Links';
import './Account.css';
import { UserContext } from 'contexts/UserContext';

const UPDATE_USER_MUTATION = gql`
  mutation ($id: uuid!, $first_name: String!, $last_name: String!, $phone: String) {
    update_user_data_by_pk(
      pk_columns: { id: $id }
      _set: { first_name: $first_name, last_name: $last_name, phone: $phone }
    ) {
      id
      first_name
      last_name
      phone
    }
  }
`;

const UPDATE_AVATAR_MUTATION = gql`
  mutation ($id: uuid!, $avatar_file_id: uuid) {
    update_user_data_by_pk(
      pk_columns: { id: $id }
      _set: { avatar_file_id: $avatar_file_id }
    ) {
      id
      avatar_file_id
    }
  }
`;

const accountData = routeData[PATHS.account];

const Account = () => {
  const { showNotification } = useContext(DialogContext);
  const { authUser, setAuthUser } = useContext(UserContext);
  const [mutateUser, { loading: isLoading }] = useMutation(UPDATE_USER_MUTATION);
  const [mutateAvatar, { loading: isAvatarLoading }] = useMutation(UPDATE_AVATAR_MUTATION);

  const handleOnSubmit = async data => {
    mutateUser({
      variables: {
        id: authUser.id,
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone
      },
      onError: (err) => showNotification(err),
      onCompleted: (res) => {
        setAuthUser({...authUser, first_name: res.update_user_data_by_pk?.first_name, last_name: res.update_user_data_by_pk?.last_name, phone: res.update_user_data_by_pk?.phone, displayName: res.update_user_data_by_pk?.first_name + ' ' + res.update_user_data_by_pk?.last_name});
        showNotification(MESSAGES['changed-success']);
      }
    });
  };
  const onUploadCompleted = (uploadData) => {
    if(uploadData?.id){
      mutateAvatar({variables: {
        id: authUser.id,
        avatar_file_id: uploadData.id
      }})
      setAuthUser({...authUser, avatar_file_id: uploadData.id})
    }
  }
  const onDeleteCompleted = () => {
    mutateAvatar({variables: {
      id: authUser.id,
      avatar_file_id: null
    }});
    setAuthUser({...authUser, avatar_file_id: null})
  }

  return <div className='profile-wrapper'>
      <Title icon={accountData.icon} title={accountData.title} />
      <ImageUpload
        onDeleteComplete={onDeleteCompleted}
        onComplete={onUploadCompleted}
        title={authUser.displayName}
        bucketId={'public'}
        fileId={authUser.avatar_file_id}
        maxWidth={250}
        allowDelete={true}
        style={{margin: '0 auto', borderRadius: '50%'}} />
      <Form
        id={PATHS.account}
        title={accountData.title}
        disabled={isLoading || isAvatarLoading}
        icon={accountData.icon}
        fields={accountData.fields}
        submitTitle={accountData.submitTitle}
        loading={isLoading || isAvatarLoading}
        onSubmit={handleOnSubmit}
        values={{
          firstName: authUser.first_name,
          lastName: authUser.last_name,
          phone: authUser.phone
        }} />
        <Links links={accountData.links.map(link => routeData[link])} />
  </div>
};

export default Account;