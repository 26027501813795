export const fields = {
  id: { restrict: ['create', 'update']},
  first_name: { title: 'Vorname', required: true, validation: [{validation: 'required'}, {validation: 'minChars', min: 3}, {validation: 'maxChars', max: 120}] },
  last_name: { title: 'Nachname', required: true, validation: [{validation: 'required'}, {validation: 'minChars', min: 3}, {validation: 'maxChars', max: 120}] },
  phone: { title: 'Telefon', type: 'phone' },
  avatar_file_id: { restrict: ['create', 'update'] }
};

const dbData = {
  fields,
  categories: [
    { id: 'all', fields: ['email', 'display_name', 'first_name', 'last_name', 'phone', 'company_contact_id']},
    // { id: 'online', title: 'Online-Status', icon: AdjustIcon, fields: ['date', 'location', 'version']},
  ],
  collections: ['collection_companies'],
  relationships: {
    staff: { fields: ['position']},
    account: { fields: ['email']},
    company_contact: { fields: ['company']},
  }
}

export default dbData;
