const style = {
    formWrapper: {
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        maxWidth: '400px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    field: {
        margin: '0 0 10px 0',
    },
    form: {
        width: '100%',
        boxSizing: 'border-box',
        margin: 0,
        padding: '8px 10px 8px'
    },
    consentLinkHl: {
        fontSize: '12px',
        margin: '8px 0 0',
        paddingLeft: '34px'
    },
    consentLinks: {
        margin: 0,
        color: '#555',
        listStyleType: 'square',
        '& li': {
            fontSize: '12px',
            padding: 0,
            marginLeft: '24px'
        },
        '& a': {
            fontSize: '12px',
            color: '#555'
        },
        '& a:hover': {
            textDecoration: 'underline' 
        }
    },
    checkbox: {
        margin: 0,
        '& .MuiFormControlLabel-label': {
            fontSize: '12px',
            lineHeight: 'normal'
        }
    },
    checkboxCallback: {
        margin: 0,
        '& .MuiFormControlLabel-label': {
            fontSize: '15px',
            lineHeight: 'normal',
            margin: '0 0 4px',
        }
    },
    inputIcon: {
        color: '#bbb'
    },
    hl: {
        display: 'flex',
        margin: '24px 0',
        fontSize: '170%',
        justifyContent: 'center',
        '& svg': {
            margin: '4px 8px 0 0'
        }
    },
    btn: {
        '& .MuiButton-label': {
            color: '#fff'
        },
        margin: 0,
        height: '56px'
    },
    '@media screen and (max-width: 600px)': {
        form: {
            width: '100%',
            maxWidth: '100%',
            minHeight: '100%',
        },
    }
}

export default style;
